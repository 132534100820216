import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import UserBase64DocumentUpload from "components/CustomUpload/UserBase64DocumentUpload.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import TextField from "@material-ui/core/TextField";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const AiDocumentProcessing = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const [base64Document, setBase64Document] = React.useState("");
  const [suffix, setSuffix] = React.useState("pdf");

  const [prompt, setPrompt] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");

  const [documentHash, setDocumentHash] = React.useState("");
  const [chatHistory, setChatHistory] = React.useState("");
  const [chatSummary, setChatSummary] = React.useState("");

  const [processing, setProcessing] = React.useState(false);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getChatHistory = async (documentHash_, signal = undefined) => {
      try {
        if (documentHash_.length > 0) {
          const state = await ApiService.llmRetrieveChatHistory(
            {
              env: ApiService.environment,
              documentHash: documentHash_,
            },
            signal
          );
          return state.chatHistory;
        }
      } catch (e) {
        return "";
      }
      return "";
    };

    const getLookups = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          id: `${queryParams.userId}_ai_document_hash`,
        };
        try {
          const responseData = await ApiService.getSingleLookup(params);
          const state = JSON.parse(responseData.value);
          const chatHistory_ = await getChatHistory(state.documentHash);
          setDocumentHash(state.documentHash);
          setChatHistory(chatHistory_);
          setChatSummary("");
          setQuestion("");
          setAnswer("");
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getLookups(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search]);

  const handleUpdateState = async (documentHash_) => {
    const value = JSON.stringify({ documentHash: documentHash_ });
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      id: `${queryParams.userId}_ai_document_hash`,
      type: ApiService.lookupTypes.state,
      access: ApiService.lookupPermissionTypes.private,
      value,
      flags: [],
    };
    try {
      await ApiService.upsertLookup(params);
    } catch (e) {
      console.error(e);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const showErrorMessage = (messageContent) => {
    setErrorMessage(messageContent);
    setTimeout(() => {
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const handleSetBase64Document = (base64DocumentString, extension) => {
    if (base64DocumentString !== base64Document) {
      setBase64Document(base64DocumentString);
      setSuffix(extension);
      setDocumentHash("");
      setChatHistory("");
      setChatSummary("");
      setQuestion("");
      setAnswer("");
    }
  };

  const handleLLMProcessDocument = async () => {
    if (
      suffix.toLowerCase() !== "pdf" ||
      (base64Document.length === 0 && documentHash.length === 0)
    ) {
      return;
    }

    // Set default results
    setProcessing(true);

    const params = {
      env: ApiService.environment,
      question: prompt,
      chatHistory,
    };
    if (documentHash.length > 0) {
      params.documentHash = documentHash;
    } else if (base64Document.length > 0) {
      params.documentBase64 = base64Document;
    }
    if (chatSummary.length > 0) {
      params.chatHistory = chatSummary;
    } else if (chatHistory.length === 0) {
      params.chatHistory = chatHistory;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const responseData = await ApiService.llmProcessQuestion(params, signal);
      const newEntry = `Human: ${responseData.question}\nAI: ${responseData.answer}`;
      if (responseData.chatHistory.length > 0) {
        setChatHistory(`${responseData.chatHistory}\n${newEntry}`);
      } else {
        setChatHistory(newEntry);
      }
      setChatSummary("");
      setQuestion(responseData.question);
      setAnswer(responseData.answer);
      setPrompt("");
      if (responseData.documentHash !== documentHash) {
        setDocumentHash(responseData.documentHash);
        await handleUpdateState(responseData.documentHash);
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
    setProcessing(false);
  };

  const handleClearChatHistory = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("clear-chat")}?`}
        onConfirm={() => {
          setAlert(null);
          setChatHistory("");
          setChatSummary("");
          setQuestion("");
          setAnswer("");
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const handleSummarizeChat = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("summarize-chat")}?`}
        onConfirm={() => {
          handleProcessChatSummary();
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const handleProcessChatSummary = async () => {
    setAlert(null);
    setProcessing(true);
    try {
      if (chatHistory.length > 0) {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const resposeData = await ApiService.llmProcessChatSummary(
          {
            env: ApiService.environment,
            chatHistory,
          },
          signal
        );
        setChatHistory(resposeData.chatSummary);
        setChatSummary(resposeData.chatSummary);
        setQuestion("");
        setAnswer("");
      }
      operationResult(true, t("chat-successfully-summarized"));
    } catch (e) {
      operationResult(false, e.message);
    }
    setProcessing(false);
  };

  const handleReturnData = (chat) => {
    if (chat.length === 0) {
      return [];
    }
    const lines = chat.split(/\n(?=AI:|Human:)/);
    const conversation = lines.map((message) => {
      return { message };
    });
    return conversation.reverse();
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <FileCopyIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("select-document")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <br></br>
                  <UserBase64DocumentUpload
                    setBase64Document={handleSetBase64Document}
                  />
                  <br></br>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <EqualizerIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("prompt")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <TextField
                    required
                    id="text-prompt"
                    variant="outlined"
                    fullWidth
                    value={prompt}
                    multiline
                    rows={3}
                    disabled={processing}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    color="primary"
                    style={{ float: "right", marginTop: "50px" }}
                    onClick={() => handleLLMProcessDocument()}
                    disabled={processing}
                  >
                    {processing ? t("processing") : t("process")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <ReactTable
                data={[{ answer }]}
                columns={[
                  {
                    Header: t("answer"),
                    accessor: "answer",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={1}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
                getTableProps={() => ({
                  style: { fontSize: "1rem" },
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <ReactTable
                data={[{ question }]}
                columns={[
                  {
                    Header: t("question"),
                    accessor: "question",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={1}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
                getTableProps={() => ({
                  style: { fontSize: "1rem" },
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <DataUsageIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("chat-history")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleClearChatHistory();
                  }}
                  style={{ marginTop: "28px" }}
                  disabled={processing}
                >
                  {t("clear-chat")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  disabled={processing}
                  onClick={() => handleSummarizeChat()}
                >
                  {t("summarize-chat")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(chatHistory)}
                columns={[
                  {
                    Header: t("message"),
                    accessor: "message",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="rose"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      {alert}
    </div>
  );
};

AiDocumentProcessing.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AiDocumentProcessing;
